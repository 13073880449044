import { Helmet } from "react-helmet-async";
import AboutPageIcon from "../components/Icons/AboutPageIcon";
import usePageReset from "../hooks/usePageReset";

export default function About() {
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="cultiv" />
        <meta name="description" content="Know all about Cultiv LLC" />
        <meta name="robots" content="index, follow" />
        <title>About Cultiv</title>
      </Helmet>
      <div className="w-full">
        <AboutPageIcon />
      </div>
      <div className="flex flex-col px-8">
        <div className="flex flex-col justify-center items-center gap-4 ">
          <div className="flex flex-col gap-4 justify-start items-start py-2 leading-9">
            <h1 className="text-gray-900 md:text-2xl text-xl font-bold  text-center mt-5">
              Who are we ?
            </h1>
            <p className="text-gray-800 lg:text-xl lg:leading-9 text-left md:text-lg text-sm md:leading-9	">
              Cultiv represents a group of interdependent companies providing a diverse set of
              activities all within the field of innovation. Cultiv LLC, USA was established in 2019
              as a holding company in Delaware, USA, with a counterpart company, Cultiv LLC, Egypt,
              for local representation and activities. Cultiv is not a single entity. We are a
              collective of independent companies working on a broad range of activities in the
              field of entrepreneurship & innovation. Cultiv strives to cultivate people, ideas,
              knowledge, and culture through innovation. Our primary goal is to empower
              entrepreneurs, innovators, technologists, and designers in Egypt & the MENA region.
              Cultiv’s goal is achieved through managing incubator & accelerator programs for both
              potential and existing entrepreneurs. The programs aim to improve their skills and
              help them acquire the necessary knowledge to start their business. We have taken it
              upon ourselves to deliver capacity-building sessions for potential entrepreneurs and
              provide them with innovative spaces and access to networking opportunities. We focus
              on managing projects in their early stages while paying close attention to the details
              of each program. Cultiv offers business support services, mentorship, training
              sessions, office facilities, and access to seed funding for startups through its
              accelerator program.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
